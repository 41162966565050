// DOM elements
const overlay = document.querySelector(".overlay");
const navCompassBtn = document.getElementById("nav-compass-btn");
const navSlider = document.getElementById("nav-slider");
const navLinkAll = document.querySelectorAll(".nav__link");
const contactPopout = document.getElementById("contact-popout");
const contactBtn = document.getElementById("contact-btn");
const contactBtnIcon = document.getElementById("contact-btn-sat");
const footer = document.getElementById("footer");
const btnFooter = document.querySelector(".close-footer");

// Functions
const openNav = function () {
  navSlider.classList.remove("nav__closed");
  navSlider.classList.add("nav__open");
  navCompassBtn.style.transform = "rotateZ(-90deg)";
  navCompassBtn.style.color = "var(--safety-orange-blaze-orange)";
};
const closeNav = function () {
  navSlider.classList.remove("nav__open");
  navSlider.classList.add("nav__closed");
  navCompassBtn.style.removeProperty("transform");
  navCompassBtn.style.color = "inherit";
};
const openContact = function () {
  contactPopout.classList.add("contact__open");
  contactPopout.classList.remove("contact__closed");
  contactPopout.style.backgroundColor = "var(--honey-yellow)";
  contactBtn.style.color = "var(--queen-blue)";
  contactBtn.style.removeProperty("transition");
  contactBtnIcon.style.transform = "skewX(-20deg) translateX(8rem)";
};
const closeContact = function () {
  contactPopout.classList.add("contact__closed");
  contactPopout.classList.remove("contact__open");
  contactPopout.style.backgroundColor = "transparent";
  contactBtn.style.removeProperty("transform");
  contactBtnIcon.style.removeProperty("transform");
};
// Event listeners
document.addEventListener("keydown", function (e) {
  if (contactPopout.classList.contains("contact__open")) closeContact();
  if (navSlider.classList.contains("nav__open")) closeNav();
});
navCompassBtn.addEventListener("click", () => {
  if (navSlider.classList.contains("nav__closed")) {
    openNav();
    closeContact();
  } else {
    closeNav();
  }
});
navLinkAll.forEach((link)=>{
  link.addEventListener('click', () => {
    closeNav();
    console.log('clicked link')
  });
});
contactBtn.addEventListener("click", () => {
  if (contactPopout.classList.contains("contact__closed")) {
    openContact();
    closeNav();
  } else {
    closeContact();
  }
});
